const appUtils = {

    // return one element in the given list
    // based on the latest=true property
    // if latest=true is not present, the method return the first element of the list
    getLatestVersion: (list) => {
    
        let foundVersion = null;
        
        list.forEach(element => {
            if(element.latest == true) {
                if(foundVersion == null) {
                    foundVersion = element;
                }
            }
        });

        // on prend le premier de la liste si aucun n'a la prop latest a true
        if(foundVersion == null) {
            foundVersion = list[0];
        }

        return foundVersion;
    }
};



export { appUtils };