import * as React from "react"

import Layout from '../components/Layout'
import Home from '../components/Home'

import TranslateManager from "../translations/translateManager"

// markup
const IndexPage = (props) => {
  console.log('page props : ', props);

  // pour set la langue manuellement dans l'url = http://localhost:8000/?&lang=fr
  const {location} = props;
  const params = new URLSearchParams(location.search);
  const paramLang = params.get("lang");
  console.log(paramLang);

  if(paramLang != null && TranslateManager.getCurrentLang() != paramLang) {
    TranslateManager.setLang(paramLang);
  }
  

  return (

    <Layout>
      <Home />
    </Layout>
  )
}

export default IndexPage
